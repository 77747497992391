/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./openlayers-map.scss";
import OpenLayerComponent from "../../models/open-layer/props-state";
import { connect } from "react-redux";
import * as OpenlayerStore from "../../redux/store/open-layer/open-layer.store";
import * as homePageStore from "../../redux/store/home-page/home-page.store";
import history from "../../common/history";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import Jquery from "jquery";
import { ConvertColsDataDetailViewToStandardData } from "./config/convert-data";
import UrlCollect from "../../common/url-collect";
import { geolocated } from "react-geolocated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import * as LoadingAction from '../../redux/store/loading/loading.store';
//@ts-ignore
import domtoimage from 'dom-to-image-more';
//--- Config
import {
  VectorImageLayerClassName,
  TileLayerClassName,
  Default_Feature_Style,
  Highlight_Feature_Style,
  Draw_Filter_Feature_Style,
  TileLayerClassNameBasedOnStyle,
} from './config/config';

//--- Store
import * as MapToolsPanelsStore from "../../redux/store/open-layer/map-tools-panels.store";
import * as mapHelper from "../../common/map";
import { GetPlanningByCoordWgs84 } from "../../redux/store/planning/planning.store";
import { createMapInfoMationRelatedModel } from "../../models/map-data-model-b/infomation-related";

//--- Openlayer
import TileWMS from 'ol/source/TileWMS';
import VectorImageLayer from "ol/layer/VectorImage";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate";
import { Image as ImageLayer } from "ol/layer";
import ImageWMS from "ol/source/ImageWMS";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import WKT from "ol/format/WKT";
import Layer from "ol/layer/Layer";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import { getArea, getLength } from "ol/sphere";
import Draw, { createBox } from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import GeoJSON from "ol/format/GeoJSON";
import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";
import DragBox from "ol/interaction/DragBox";
import { transform } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import Projection from 'ol/proj/Projection';

//--- Models
import { BoundariesViewModel, RelatedLayerModel } from "../../models/map-data-model-b/new-planning-relation.model";
import LayerSettingsModels from "../../models/map-data-model-b/layer-setting-models";
import SingleMapInfoMationModel from "../../models/map-data-model-b/single-map-infomation-model";
import ResponeFromServeClickShowInfomationFeature from "../../models/open-layer/respone-get-future-info-url-ajax";
import ImageLayerModels from "../../models/open-layer/image-layer";
import BoundariesModel from "../../models/map-data-model-b/new-boundaries.model";
import * as config from "../../utils/configuration";
import GetAllPlanningBoundariesMode from "../../models/map-data-model-b/get-all-planning-boundaries.model";
import DrawnFilterDataModel from '../../models/open-layer/drawn-filter-data.model';
import MapContainer from './childs/map-container.view';
import Notification from '../../common/notification-service';

import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import * as mapToolAction from "../../redux/store/map-tool/map-tool.store";
import GeometryType from "ol/geom/GeometryType";
import { stringToGlsl } from "ol/style/expressions";

import * as viLanguage from "../../languages/vi";
import {
  fixedDisplayNameQHC_SDD,
  fixedDisplayNameQHC_GIAOTHONG,
  fixedDisplayNameQHPK_SDD,
  fixedDisplayNameQHPK_GIAOTHONG,
  fixedDisplayNameQHCT_SDD,
  fixedDisplayNameQHCT_GIAOTHONG,
} from "../../mock-data/fixedDataGroupLayers.mock";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";
import AssistiveChipsView from "./assistive-chips/assistive-chips.view";
import { Pixel } from "ol/pixel";

var selected: any = null;
var sketchGolbal: Feature | null;
var helpTooltipElementGolbal: HTMLElement;
var helpTooltipGolbal: Overlay;
var measureTooltipElementGolbal: HTMLElement | null;
var measureTooltipGolbal: Overlay;
var drawMeasureGolbal: any;
var selectClick = new Select({
  condition: click,
  style: Highlight_Feature_Style
});
var select: any = null; // ref to currently selected interaction
const classNameVectorLayerFilterDrawn = 'dawn-filter-vector-layer';
const DrawnFilterData: DrawnFilterDataModel = {
  draw: null,
  source: null,
  filterType: '',
}

const animateDurationZoomedOut: number = 1000;
const animateDurationZoomedIn: number = 1000;

class OpenlayersMapView extends React.Component<
  OpenLayerComponent.PropsType,
  OpenLayerComponent.StateType
> {
  constructor(props: OpenLayerComponent.PropsType) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
      currentLayer: null,
      currentLayerSettingModel: null,
      useFeatureViewInfomationOnClick: false,
      modals: false,
      coordinate: this.props.coordinate,
      listLayerSettingModel: [],
      primaryIdLayer: 0,
      isLoadedAllVectorSource: false,
      currentZoom: 11,
      prevFeatureId: "",
      currentCoordinate: [],
      isAddDetail: false,
      zIndexPolygon: 500,
      zIndexLine: 1000,
      zIndexPoint: 1500,
      projectTooltipCoordinate: null,
      currentClickCoordinate: [],
    };
  }

  getPlanningLayerByCoord = (lng: any, lat: any) => {
    GetPlanningByCoordWgs84(lng, lat).then(res => {
      if (res && res.content && res.content.length > 0) {
        this.setState({ currentClickCoordinate: [lng, lat] });
        return res.content?.filter((item: any) => item.planning.id != this.props.planningId)
      } else return []
    }).then(res => {
      this.props.SaveInfomationRelatedList(createMapInfoMationRelatedModel(res));
      this.props.SaveInfomationRelatedTable([]);
    }).catch(err => { console.log(err) })
  }

  toggleDisplayVectorLayer = (
    boundaries: BoundariesModel,
    isDisplay: boolean,
    minZoom: number = 1,
    maxZoom: number = 20,
    tooltipValue: string = "",
    planningId: number = 0
  ) => {
    const LayersCurrents = this.state.map?.getLayers();
    let isBoundariesAdded = false;
    LayersCurrents?.forEach((layerModel: Layer) => {
      if (layerModel.getClassName() === `id-layer-${boundaries.nameTb}`) {
        isBoundariesAdded = true;
        layerModel.setVisible(isDisplay);
        return;
      }
    });
    if (!isBoundariesAdded) {
      const format = new WKT();

      const feature: any[] = [];
      const featureObject = format.readFeature(boundaries.geomText, {
        dataProjection: this.props.projection,
        featureProjection: this.props.projection,
      });
      featureObject.setProperties({ tooltipValue: tooltipValue });
      featureObject.setProperties({ planningId: planningId });
      feature.push(featureObject);

      LayersCurrents?.push(
        new VectorLayer({
          className: `id-layer-${boundaries.nameTb}`,
          minZoom: minZoom,
          maxZoom: maxZoom,
          source: new VectorSource({
            features: [...feature],
          }),
        })
      );
    }
  };

  /*
  * Khi click vao phan chon mot quy hoach lien quan o left menu
  * Thi goi vao ham nay
  * Thong tin gui sang bao gom: Boundaries cua layer va thong tin layer, trong do da bao gom
  * display_name co chua cols
  *
  * */

  toggleDisplayPlanningRelated = (relatedLayer: RelatedLayerModel, isDisplay: boolean) => {
    if(!relatedLayer) return;
    const LayersCurrents = this.state.map?.getLayers();
    let isLayerAdded = false;
    let selectedLayer = config.DataStore + ':' + relatedLayer.table;
    LayersCurrents?.forEach((layerModel: Layer) => {
      if (layerModel.getClassName() === `related-layer-${relatedLayer.id}`) {
        isLayerAdded = true;
        layerModel.setVisible(isDisplay);
      }
    });
    if (!isLayerAdded) {
      const selectSource = new TileWMS({
        url: relatedLayer.wms,
        params: {
          className: 'related-map',
          'LAYERS': selectedLayer,
          LayerId: relatedLayer.id,
          FORMAT: "image/png",
          VERSION: "1.1.0",
        }
      })

      const selectLayer = new TileLayer({
        className: `related-layer-${relatedLayer.id}`,
        visible: isDisplay,
        opacity: .5,
        source: selectSource,
      });
      selectLayer.setZIndex(1);
      LayersCurrents.push(selectLayer);
    }
    if (isDisplay) {
      this.clickViewInfomationRelatedLayer(this.state.currentClickCoordinate, relatedLayer)
      this.pinSearchMode(this.state.currentClickCoordinate, true)
    } else {
      this.props.SaveInfomationRelatedTable([]);
      this.removeHiglightVectorLayerByClass("highlight-vectorlayer-related");
      this.removeHiglightVectorLayerByClass("pin-marker-vector-layer");
    }
  }

  clickViewInfomationRelatedLayer = (coordinate: any, layer: any) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    const view = mapObject.getView();
    const viewResolution = view.getResolution();
    const getFeaturesWithTitleLayer = (titleLayer: any) => {
      if (!titleLayer) return;
      const source: any = titleLayer.getSource();
      const url = source.getFeatureInfoUrl(
        coordinate,
        viewResolution,
        view.getProjection(),
        { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
      );
      if (url) {
        Jquery.ajax({
          type: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: (featureCollection: ResponeFromServeClickShowInfomationFeature) => {
            if (featureCollection.features.length > 0) {
              const _geojsonObject = featureCollection;
              const ListInfoMation: SingleMapInfoMationModel[] = [];

              const featureInfo: any = featureCollection.features[0];
              _geojsonObject.features = [featureInfo];

              /*
              * Lay thong tin de hien len bang thuoc tinh ben tay trai
              * */

              const setDataForTabInformation = (featuresProperies: any) => {
                console.log('check featuresProperies', featuresProperies);
                // if (this.props.isProject && this.props.setDataProject) {
                //   this.props.setDataProject(featureCollection.features[0].id)
                // }
                const layerSettingModel = layer;
                console.log('layerSettingModel', layerSettingModel);
                const colsRaw = ConvertColsDataDetailViewToStandardData(
                  layerSettingModel.display_name.cols
                );
                // console.log('colsRaw', colsRaw);
                const displayDetails = layerSettingModel.display_name.viewdetail;
                // console.log('displayDetails', displayDetails);
                ListInfoMation.push({ label: 'id', value: layer.id })
                if (displayDetails.use) {
                  displayDetails.cols.map((columnName: any) => {
                    ListInfoMation.push({
                      label: colsRaw[columnName],
                      value: featuresProperies[columnName],
                    });
                  });
                }
              }
              // n.features.map(features => MergeData(features.properties))
              console.log('featureCollection__xxx', featureCollection);
              setDataForTabInformation(featureCollection.features[0].properties)

              this.props.SaveInfomationRelatedTable(ListInfoMation);
              console.log('check ListInfoMation', ListInfoMation);

              /********************************************************************/

              let _isHaveHighlightVectorlayer = false;
              listLayer.forEach((_layer) => {
                if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer-related")) {
                  _isHaveHighlightVectorlayer = true;
                  const _newVectorSource = new VectorSource({
                    features: new GeoJSON().readFeatures(_geojsonObject),
                  })

                  _layer.setSource(_newVectorSource)
                  _layer.setVisible(true);
                }
              })
              if (!_isHaveHighlightVectorlayer) {
                console.log('_geojsonObject', _geojsonObject)
                listLayer.push(
                  new VectorLayer({
                    className: "highlight-vectorlayer-related",
                    source: new VectorSource({
                      features: new GeoJSON().readFeatures(_geojsonObject),
                    }),
                    zIndex: 9999,
                    style: new Style({
                      stroke: new Stroke({
                        color: 'rgba(0,255,255,1)',
                        width: 2,
                      }),
                      fill: new Fill({
                        color: 'rgba(255,16, 188,0.3)',
                      }),
                    }),
                  })
                )
              }
            }
            // this.removeHiglightVectorLayer();

            // if (this.props.isProject && this.props.setDataProject) this.props.setDataProject(".")

            // layerIndex++;
            // if (layerIndex < listTitleLayerVisible.length) {
            //   getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
            // } else {
            //   this.props.SaveInfomationList([]);
            //   this.props.SaveInfomationRelatedList([])
            // }

          }
        })
      }
    }
    let currentLayer = null;
    listLayer.forEach((layer) => {
      console.log('layer', layer)
      if (
        (layer instanceof TileLayer) &&
        (layer.getClassName() != 'ol-layer') &&
        (layer.getClassName().includes("related-layer"))
        && layer.getVisible()
      ) {
        currentLayer = layer;
      }
    })
    getFeaturesWithTitleLayer(currentLayer);
  }

  toggleDisplayLayerRelated = (
    boundaries: BoundariesViewModel,
    relatedLayer: RelatedLayerModel,
    isDisplay: boolean
  ) => {
    if (relatedLayer && relatedLayer.id && relatedLayer.id > 0) {
      const LayersCurrents = this.state.map?.getLayers();
      let isLayerAdded = false;
      LayersCurrents?.forEach((layerModel: Layer) => {
        if (layerModel.getClassName() === `id-layer-${relatedLayer.id}`) {
          isLayerAdded = true;
          layerModel.setVisible(isDisplay);
        }
      });
      if (!isLayerAdded) {
        /*
        * Neu quy hoach lien quan can hien thi boundaries
        * */

        if (Array.isArray(boundaries) && boundaries.length > 0) {
          const format = new WKT();
          const feature: any[] = [];
          feature.push(
            format.readFeature(boundaries.geomText, {
              dataProjection: this.props.projection,
              featureProjection: this.props.projection,
            })
          );
          LayersCurrents?.push(
            new VectorLayer({
              className: `id-layer-${boundaries.nameTb}`,
              source: new VectorSource({
                features: [...feature],
              }),
            })
          );
        }


        if (relatedLayer && relatedLayer.table && relatedLayer.wms) {
          this.setState({
            relatedLayer: relatedLayer
          })
          let selectedLayer = config.DataStore + ':' + relatedLayer.table;
          //console.log(this.state.listLayerSettingModel);
          //console.log('Show related map info by Title WMS');
          const selectSource = new TileWMS({
            url: relatedLayer.wms,
            params: {
              className: 'related-map',
              'LAYERS': selectedLayer,
              LayerId: relatedLayer.id,
              FORMAT: "image/png",
              VERSION: "1.1.0",
            }
          })

          //console.log('selectSource',selectSource);

          const selectLayer = new TileLayer({
            className: `id-layer-${relatedLayer.id}`,
            visible: true,
            opacity: 1,
            source: selectSource,
          });
          selectLayer.setZIndex(999999);
          LayersCurrents.push(selectLayer);
        }
      }
    }
  };

  changeBaseMap = (newBasemapUrl: string | undefined) => {
    const LayersCurrents = this.state.map?.getLayers();
    if (newBasemapUrl) {
      LayersCurrents?.insertAt(
        0,
        new TileLayer({
          source: new OSM({
            url: newBasemapUrl,
            maxZoom: this.props.maxZoom,
          }),
        })
      );
      LayersCurrents?.removeAt(1);
    } else {
      const BaseMapLayer = LayersCurrents?.item(0).setVisible(false);
    }
  };

  changeCurrentLayerOpacity = (
    opacity: number | undefined,
    layer?: LayerSettingsModels.LayerSettingsModel | any
  ) => {
    if (opacity) {
      if (layer) this.toggleDisplayLayer(layer, opacity);
    }
  };

  changeCurrentLayer = (layer: LayerSettingsModels.LayerSettingsModel) => {
    const LayersCurrents = this.state.map?.getLayers();
    const newStates: any = { currentLayerSettingModel: layer };
    LayersCurrents?.forEach(
      (layerElement: ImageLayerModels.ImageLayerModel) => {
        const ParamsProperty = layerElement.getProperties().source.params_;
        // const LayerClassName = layerElement.className_;
        if (ParamsProperty && ParamsProperty.LayerId === layer.id) {
          newStates.currentLayer = layerElement;
          return;
        }
      }
    );
    this.setState(
      newStates,
      () => {
        this.changeCurrentLayerOpacity(
          Number(layer.opacity) / 100,
          layer
        );
      }
    );
  };

  toggleDisplayLayer = (
    layer: LayerSettingsModels.LayerSettingsModel,
    opacity?: number
  ) => {
    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach(
      (layerElement: ImageLayerModels.ImageLayerModel) => {
        const ParamsProperty = layerElement.getProperties().source.params_;
        const layerClassName = layerElement.className_;
        // vì phải phân biệt loại Polygon, Line hay Point để xác định loại zIndex tương ứng
        // nên so sánh bằng id thôi là ko đủ, phải dùng className

        // if (ParamsProperty && ParamsProperty.LayerId === layer.id) {
        //   layerElement.setVisible(layer.is_check);
        //   layerElement.setZIndex(layer.z_index, true);
        // }

        if (ParamsProperty && ParamsProperty.LayerId === layer.id) {
          var styleValue = '';
          if (layerClassName.includes('Polygon')) {
            styleValue = 'Polygon';
          }
          if (layerClassName.includes('Line')) {
            styleValue = 'Line';
          }
          if (layerClassName.includes('Point')) {
            styleValue = 'Point';
          }
          layerElement.setVisible(layer.is_check);
          if (styleValue && layer.is_check) {
            layerElement.setZIndex(this.getZIndexBasedOnStyle(styleValue), true);
          }
        }

        if (layerClassName === VectorImageLayerClassName(layer.id)) {
          layerElement.setVisible(layer.is_check);
          layerElement.setZIndex(layer.z_index, true);
          // if (opacity) {
          //   layerElement.setOpacity(opacity);
          // }
        }
        if (opacity) {
          if (ParamsProperty && ParamsProperty.LayerId !== layer.id) {
            layerElement.setOpacity(opacity);
          }
        }
      }
    );
  };

  animatePromise = (zoomVal: any, lat: any, lng: any) => {
    const mapObject = this.state.map;
    return new Promise((resolve) => {
      mapObject.getView().animate({
        zoom: zoomVal,
        duration: this.state.currentZoom > 16.5 ? animateDurationZoomedIn : animateDurationZoomedOut,
        center: [lat ? parseFloat(lat) : 0, lng ? parseFloat(lng) : 0],
      });

      setTimeout(resolve, this.state.currentZoom > 16.5 ? animateDurationZoomedIn : animateDurationZoomedOut);
    });
  }

  addLayerManually = (tableName: string, isAddedLayer: boolean, isChecked: boolean, style: string) => {
    console.log('tableName', tableName);
    console.log('isAddedLayer', isAddedLayer);
    console.log('isChecked', isChecked);

    if (!isAddedLayer) {
      const layerImage: any = new TileLayer({
        visible: true,
        zIndex: this.getZIndexBasedOnStyle(style) || 9999,
        minZoom: 0,
        maxZoom: 20,
        source: new TileWMS({
          url: 'https://geo.cgis.asia/geoserver/pleiku/wms/',
          params: {
            //LAYERS: `qhdongtrieu:${layerData.table}`,
            LAYERS: `${config.DataStore}:${`${tableName}`}`,
            LayerId: 100,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: 'anonymous'
        }),
        className: `added_manually_${TileLayerClassNameBasedOnStyle(tableName, style)}`,
      });
      this.state.map?.addLayer(layerImage);
    }

    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach(
      (layerElement: ImageLayerModels.ImageLayerModel) => {
        if (!layerElement) return;
        const layerClassName = layerElement.className_;
        if (
          (layerClassName.includes('Polygon') && layerClassName === `added_manually_title-layer-Polygon-${tableName}`) ||
          (layerClassName.includes('Line') && layerClassName === `added_manually_title-layer-Line-${tableName}`) ||
          (layerClassName.includes('Point') && layerClassName === `added_manually_title-layer-Point-${tableName}`)
        ) {
          // this.state.map?.removeLayer(layerElement);
          // layerElement.setVisible(!layerElement.getVisible());
          layerElement.setVisible(isChecked);
          if (isChecked) {
            layerElement.setZIndex(this.getZIndexBasedOnStyle(style), true);
          }
        }
      }
    );
    console.log("getLayers", this.state.map?.getLayers());

  }

  getZIndexBasedOnStyle = (style: string, id?: number) => {
    var result = 1;
    //Lớp để chọn -> luôn cho lên cao nhất
    if (id == planningIdInfo.HOME_MAP_LAYER_ID) return 9999;
    if (id == planningIdInfo.PROJECT_MAP_LAYER_ID) return 9999;
    if (!style) return null;
    if (style.includes("Polygon")) {
      result = this.state.zIndexPolygon;
      this.setState((prevState) => ({
        zIndexPolygon: prevState.zIndexPolygon + 1
      }));
      return result;
    }
    if (style.includes("Line")) {
      result = this.state.zIndexLine;
      this.setState((prevState) => ({
        zIndexLine: prevState.zIndexLine + 1
      }));
      return result;
    }
    if (style.includes("Point")) {
      result = this.state.zIndexPoint;
      this.setState((prevState) => ({
        zIndexPoint: prevState.zIndexPoint + 1
      }));
      return result;
    }
  }

  addLayerManuallyFIXED = (fixedArray: any) => {
    const ListLayers: any[] = [];

    fixedArray['giaothong'].map((item: any) => {
      const layerImage: any = new TileLayer({
        visible: true,
        zIndex: this.getZIndexBasedOnStyle(item.style) || 9999,
        minZoom: 0,
        maxZoom: 20,
        source: new TileWMS({
          url: 'https://geo.cgis.asia/geoserver/pleiku/wms/',
          params: {
            //LAYERS: `qhdongtrieu:${layerData.table}`,
            LAYERS: `${config.DataStore}:${`${item.tableName}`}`,
            LayerId: 100,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: 'anonymous'
        }),
        className: `added_manually_${TileLayerClassNameBasedOnStyle(item.tableName, item.style)}`,
      });
      this.state.map?.addLayer(layerImage);
    });

    fixedArray['sdd'].map((item: any) => {
      const layerImage: any = new TileLayer({
        visible: true,
        zIndex: this.getZIndexBasedOnStyle(item.style) || 9999,
        minZoom: 0,
        maxZoom: 20,
        source: new TileWMS({
          url: 'https://geo.cgis.asia/geoserver/pleiku/wms/',
          params: {
            //LAYERS: `qhdongtrieu:${layerData.table}`,
            LAYERS: `${config.DataStore}:${`${item.tableName}`}`,
            LayerId: 100,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: 'anonymous'
        }),
        className: `added_manually_${TileLayerClassNameBasedOnStyle(item.tableName, item.style)}`,
      });
      this.state.map?.addLayer(layerImage);
    });
  }

  resetLayerOpacity = () => {
    console.log(" run resetLayerOpacity ");

    const layersList = this.state.map?.getLayers();
    layersList?.forEach((layerElement: ImageLayerModels.ImageLayerModel) => {
      layerElement.setOpacity(1);
    }
    );
  }

  handleZoomOut = () => {
    this.state.map?.getView().setZoom(this.state.map.getView().getZoom() - 1);
  };

  handleZoomIn = () => {
    this.state.map?.getView().setZoom(this.state.map.getView().getZoom() + 1);
  };

  handleSelectOne = () => {
    this.handleOnOffViewInfomation(true);
  };

  SpatialSearch = async (coordinate: any) => {
    const layerGroups = this.props.listGroupLayer;
    const tableName = layerGroups && layerGroups.length > 0 && this.checkPlanningOfEarth(layerGroups);
    let _data = {
      "tbName": tableName,
      "lang": "vn",
      "shp": coordinate,
      "r": 0,
      "searchType": "Polygon"
    };
    try {
      const res = await mapToolAction.SpatialSearch(_data);
      return res;
    } catch (error) {
      throw error
    }
  }

  checkPlanningOfEarth = (layerGroups: any) => {
    if (!layerGroups) return;
    let layerOfEarth = layerGroups && layerGroups.length > 0 && layerGroups.map((item: any) => {
      return item.layer_settings
    })
    if (layerOfEarth && Array.isArray(layerOfEarth) && layerOfEarth.length > 0) {
      for (let i = 0; i < layerOfEarth.length; i++) {
        if (typeof layerOfEarth[i][0] != 'undefined' && layerOfEarth[i][0].select_multi === true) {
          return layerOfEarth[i][0].table;
        }
      }
    }
  }

  handleSelectMany = () => {
    this.removeHiglightVectorLayer();
    this.clearFeatures();
    this.setHeaderForTable();
    let select = new Select();

    this.state.map?.addInteraction(select);

    let selectedFeatures = select.getFeatures();

    let dragBox = new DragBox({});

    let _idTimeout: any = null;

    this.state.map?.addInteraction(dragBox);

    dragBox.on("boxend", async () => {
      let rotation = this.state.map?.getView().getRotation();
      let oblique = rotation % (Math.PI / 2) !== 0;
      let candidateFeatures = selectedFeatures;
      let extent = dragBox.getGeometry().getExtent();
      const coordinate = dragBox.getGeometry().getCoordinates()[0];
      console.log("dragBox.getGeometry().getCoordinates()", dragBox.getGeometry().getCoordinates()[0])
      let stringCoordinate = ""
      for (let i = 0; i < coordinate.length; i++) {
        stringCoordinate = stringCoordinate.concat(`${coordinate[i][0]} ${coordinate[i][1]}${i === coordinate.length - 1 ? '' : ','}`);
      }
      const preString = "POLYGON((";
      const endString = "))"
      const coordinateSearch = preString + stringCoordinate + endString;
      console.log("coordianteSearch", coordinateSearch);
      const res = await this.SpatialSearch(coordinateSearch);
      const properties = [];
      const boundaries = [];
      if (res && res.content) {
        let feature = JSON.parse(res.content);
        if (feature && feature.data) {
          const _data = feature.data;
          for (let i = 0; i <= _data.length; i++) {
            if (_data[i]?.geo) {
              boundaries.push(_data[i].geo);
              delete _data[i].geo;
            }
            _data[i]?.geom && delete _data[i].geom;
            _data[i] && properties.push(_data[i]);
          }
        }
        if (_idTimeout) {
          clearInterval(_idTimeout)
        }
        _idTimeout = setTimeout(() => {
          const btnRelativeResultTab = document.getElementById(
            "btnRelativeResultTab"
          );
          if (btnRelativeResultTab) {
            if (btnRelativeResultTab.classList.contains("collapsed"))
              btnRelativeResultTab.click();
          }
        }, 100)
        this.functionHightLightPolygon(boundaries);
        this.props.SaveInfomationForTable(properties);

      } else {
        _idTimeout = null;
      }
    });

    // clear selection when drawing a new box and when clicking on the map
    dragBox.on("boxstart", function () {
      selectedFeatures.clear();
    });

    // selectedFeatures.on(["add", "remove"], () => {
    //   var properties = selectedFeatures.getArray().map(function (feature) {
    //     return feature.getProperties();
    //   });


    // });
  };

  removeInteractionOnMap = () => {
    let interactionArr = this.state.map?.getInteractions().array_;
    let length = interactionArr.length;
    if (interactionArr && length === 0) return;
    interactionArr.map((item: any, index: number) => {
      if (item instanceof DragBox) {
        this.state.map?.getInteractions().array_.splice(index, 1);
      }
    })
  }

  handleExportMap = (isSave: boolean) => {
    const _Self = this;
    _Self.props.ShowLoading()
    const _openlayerMapObject: Map = this.state.map;
    const _size = _openlayerMapObject.getSize();
    const _exportOptions = {
      width: _size[0],
      height: _size[1],
      filter: function (_element: HTMLElement) {
        const _className = _element.className || '';
        return (
          _className.indexOf('ol-control') === -1 ||
          _className.indexOf('ol-scale') > -1 ||
          (_className.indexOf('ol-attribution') > -1 &&
            _className.indexOf('ol-uncollapsible'))
        );
      },
    };

    domtoimage.toJpeg(_openlayerMapObject.getViewport(), _exportOptions)
      .then(function (dataUrl: string) {
        _Self.props.HiddenLoading()
        if (isSave) {
          const _linkElement = document.createElement('a');
          _linkElement.setAttribute('download', `map.jpg`)
          _linkElement.setAttribute("href", dataUrl);
          _linkElement.click();
        } else {
          document
            .getElementById("imagemap")
            ?.setAttribute("src", dataUrl);
          document.getElementById("print")?.click();
        }
        (document.querySelector('[class="tool-button"]') as HTMLElement)?.click();
      });
  };
  handleOnOffViewInfomation = (isTurnOn: boolean) => {
    // this.removeHiglightVectorLayer();
    this.deleteHightlightFilterLayer();
    const mapObject: Map = this.state.map;
    if (isTurnOn) {
      mapObject.on("singleclick", this.functionClickViewInfomationListener);
    } else {
      mapObject.un("singleclick", this.functionClickViewInfomationListener);
    }
    this.changeInteraction();
  };

  handleDisplayFullScreenViewMode = (isFullScreen: boolean) => {
    const HeaderContainer = document.getElementById(
      "header-map-page-container"
    ) as HTMLElement;
    if (HeaderContainer) {
      if (isFullScreen) {
        HeaderContainer.style.display = "none";
        this.setHeightViewport();
      } else {
        HeaderContainer.style.display = "block";
        this.setHeightViewport();
      }
    }
  };

  handlePinMarkerMode = (isTurnOn: boolean) => {
    const LayersCurrents = this.state.map?.getLayers();
    const openlayerMap: Map = this.state.map;
    const anchorXUnits: any = "fraction";
    const anchorYUnits: any = "pixels";
    if (isTurnOn) {
      const PinMarkerLayer = new VectorLayer({
        zIndex: 9999,
        className: "pin-marker-vector-layer",
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 22],
            anchorXUnits: anchorXUnits,
            anchorYUnits: anchorYUnits,
            src: require("../../assets/icon/red-marker.png"),
          }),
        }),
      });
      LayersCurrents?.push(PinMarkerLayer);
      openlayerMap.on("singleclick", this.functionClickPinMarker_PinMarkerMode);
    } else {
      let layerIndex = -1;
      LayersCurrents?.forEach((layerObject: Layer, index: number) => {
        if (layerObject.getClassName() === "pin-marker-vector-layer") {
          layerIndex = index;
          return;
        }
      });
      if (layerIndex != -1) LayersCurrents?.removeAt(layerIndex);
      openlayerMap.un("singleclick", this.functionClickPinMarker_PinMarkerMode);
    }
  };

  handleMeasureMode = (isTurnOn: boolean, isLineMode: boolean) => {
    const LayersCurrents = this.state.map?.getLayers();
    let indexMeasureLayer = -1;
    let isLayerHasExist = false;
    LayersCurrents?.forEach((layerObject: Layer, index: number) => {
      if (layerObject.getClassName() === "measuare-vector-layer") {
        isLayerHasExist = true;
        indexMeasureLayer = index;
        return;
      }
    });
    if (isTurnOn) {
      let measureVectorSource = new VectorSource();
      if (!isLayerHasExist) {
        const measureVectorLayer = new VectorLayer({
          zIndex: 1000,
          className: "measuare-vector-layer",
          source: measureVectorSource,
          style: new Style({
            fill: new Fill({
              color: "rgba(255, 0, 0, 0.6)",
            }),
            stroke: new Stroke({
              color: "#ffcc33",
              width: 3,
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: "#ffcc33",
              }),
            }),
          }),
        });
        LayersCurrents.push(measureVectorLayer);
        this.state.map.on(
          "pointermove",
          this.functionPointerMoveHandle_MeasureMode
        );
        this.state.map
          .getViewport()
          .addEventListener("mouseout", this.functionMouseOut_MeasureMode);
      } else {
        const MeasureVectorLayer: VectorLayer = LayersCurrents?.item(
          indexMeasureLayer
        );
        measureVectorSource = MeasureVectorLayer.getSource();
      }
      this.state.map.removeInteraction(drawMeasureGolbal);
      this.functionAddInteraction_MeasureMode(isLineMode, measureVectorSource);
    } else {
      this.state.map.removeInteraction(drawMeasureGolbal);
      this.functionRemoveAllMapOverlay();
      this.state.map.un(
        "pointermove",
        this.functionPointerMoveHandle_MeasureMode
      );
      this.state.map
        .getViewport()
        .removeEventListener("mouseout", this.functionMouseOut_MeasureMode);
      if (indexMeasureLayer != -1) LayersCurrents?.removeAt(indexMeasureLayer);
    }
  };

  //--- core function

  clearFeaturesSelected = () => {
    this.props.featuresSelected?.map(features => {
      features.setStyle(Default_Feature_Style(features))
    })
    this.props.SaveOpenlayerFeaturesSelected && this.props.SaveOpenlayerFeaturesSelected([]);
    this.deleteFilterDrawnVectorLayer();
    this.deleteHightlightFilterLayer()
  }

  functionTurnOffFilterMode = () => {
    const filterButtonElement = document.querySelector(
      '[data-icon-name="filter"]'
    );
    if (filterButtonElement) {
      if (filterButtonElement.classList.contains("active")) {
        const informationTool = (document.querySelector(".tool-button") as HTMLElement);
        informationTool?.click();
      }
    }
    /*
    const mapInformationTabButton = (document.querySelector('[aria-controls="mapLayersTab"]') as HTMLElement);
    mapInformationTabButton?.click();
    */
    this.props.SetDisplayInfomationPopup && this.props.SetDisplayInfomationPopup(false);
    this.clearFeaturesSelected();
    this.deleteFilterDrawnVectorLayer();
  }

  functionDisplayAllBoundariesOfAllPlanning = (
    ListBoundaries: GetAllPlanningBoundariesMode[]
  ) => {
    const idIntervalCheckMapExist = setInterval(() => {
      if (this.state.map) {
        const vectorLayerStyle = new Style({
          stroke: new Stroke({
            color: "rgba(215,78,0,0.8)",
            width: 1,
          }),
          fill: new Fill({
            color: "rgba(215,78,0,0.1)",
          }),
        });
        const LayersCurrents = this.state.map?.getLayers();
        ListBoundaries.map((boundariesData) => {
          const format = new WKT();

          const feature: any[] = [];
          const featureObject = format.readFeature(boundariesData.geomText, {
            dataProjection: this.props.projection,
            featureProjection: this.props.projection,
          });
          featureObject.setProperties({ tooltipValue: boundariesData.name });
          featureObject.setProperties({ planningId: boundariesData.id });
          featureObject.setProperties({ acreage: boundariesData.acreage });
          featureObject.setProperties({
            population: boundariesData.population,
          });
          featureObject.setProperties({ report: boundariesData.report });
          feature.push(featureObject);

          LayersCurrents.push(
            new VectorLayer({
              className: `id-layer-${boundariesData.nameTable}`,
              maxZoom: boundariesData.maxZoom,
              minZoom: boundariesData.minZoom,
              zIndex: boundariesData.zIndex,
              source: new VectorSource({
                features: feature,
              }),
              style: vectorLayerStyle,
            })
          );

        });
        console.log(LayersCurrents, 'current layer')
        clearInterval(idIntervalCheckMapExist);
      }
    }, this.state.currentZoom > 16.5 ? animateDurationZoomedIn : animateDurationZoomedOut);
  };

  functionRemoveAllMapOverlay = () => {
    const CollectsOfOverlay = this.state.map.getOverlays();
    CollectsOfOverlay.clear();
  };

  functionAddInteraction_MeasureMode = (
    isLineMode: boolean,
    vectorSource: VectorSource
  ) => {
    let type: any = isLineMode ? "LineString" : "Polygon";
    drawMeasureGolbal = new Draw({
      source: vectorSource,
      type: type,
      style: new Style({
        fill: new Fill({
          color: "rgba(234, 255, 0,0.5)",
        }),
        stroke: new Stroke({
          color: "rgba(255, 0, 0, 0.8);",
          // lineDash: [10, 10],
          width: 3,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(255,0,0,0.3);",
          }),
          fill: new Fill({
            color: "rgba(29, 32, 232,1)",
          }),
        }),
      }),
    });
    this.state.map?.addInteraction(drawMeasureGolbal);

    this.functionCreateMeasureTooltip_MeasureMode();
    this.functionCreateHelpTooltip_MeasureMode();

    let listener: any;
    const self = this;
    drawMeasureGolbal.on("drawstart", (evt: any) => {
      sketchGolbal = evt.feature;

      let tooltipCoord = evt.coordinate;

      listener = sketchGolbal?.getGeometry().on("change", function (evt) {
        const geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = self.functionFormatPolygon_MeasureMode(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = self.functionFormatLength_MeasureMode(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        if (measureTooltipElementGolbal)
          measureTooltipElementGolbal.innerHTML = output || "";
        measureTooltipGolbal.setPosition(tooltipCoord);
      });
    });

    drawMeasureGolbal.on("drawend", function () {
      if (measureTooltipElementGolbal)
        measureTooltipElementGolbal.className = "ol-tooltip ol-tooltip-static";
      measureTooltipGolbal.setOffset([0, -7]);
      sketchGolbal = null;
      measureTooltipElementGolbal = null;
      self.functionCreateMeasureTooltip_MeasureMode();
      unByKey(listener);
    });
  };

  functionCreateHelpTooltip_MeasureMode = () => {
    helpTooltipElementGolbal?.parentNode?.removeChild(helpTooltipElementGolbal);
    helpTooltipElementGolbal = document.createElement("div");
    helpTooltipElementGolbal.className = "ol-tooltip hidden";
    const positioning: any = "center-left";
    helpTooltipGolbal = new Overlay({
      element: helpTooltipElementGolbal,
      offset: [15, 0],
      positioning: positioning,
    });
    this.state.map.addOverlay(helpTooltipGolbal);
  };

  functionCreateMeasureTooltip_MeasureMode = () => {
    measureTooltipElementGolbal?.parentNode?.removeChild(
      measureTooltipElementGolbal
    );
    measureTooltipElementGolbal = document.createElement("div");
    measureTooltipElementGolbal.className = "ol-tooltip ol-tooltip-measure";
    const positioning: any = "bottom-center";
    measureTooltipGolbal = new Overlay({
      element: measureTooltipElementGolbal,
      offset: [0, -15],
      positioning: positioning,
    });
    this.state.map.addOverlay(measureTooltipGolbal);
  };

  functionFormatLength_MeasureMode = (line: LineString): string => {
    const length = getLength(line, {
      projection: this.props.projection,
    });
    let result = "";
    if (length > 100) {
      result = Math.round((length / 1000) * 100) / 100 + " " + "km";
    } else {
      result = Math.round(length * 100) / 100 + " " + "m";
    }

    return result;
  };

  functionFormatPolygon_MeasureMode = (polygon: Polygon): string => {
    const area = getArea(polygon, {
      projection: this.props.projection,
    });
    let result = "";
    if (area > 10000) {
      result =
        Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
    } else {
      result = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
    }
    return result;
  };

  functionMouseOut_MeasureMode = () => {
    helpTooltipElementGolbal?.classList.add("hidden");
  };

  functionPointerMoveHandle_MeasureMode = (event: MapBrowserEvent) => {
    if (event.dragging) return;
    if (sketchGolbal) {
      const geom = sketchGolbal.getGeometry();
      if (geom instanceof Polygon) {
      } else if (geom instanceof LineString) {
      }
    }
    if (helpTooltipElementGolbal) {
      helpTooltipGolbal.setPosition(event.coordinate);

      helpTooltipElementGolbal.classList.remove("hidden");
    }
  };

  handleSearchCoordinateMapDetail = async (coord: any) => {
    if (coord) console.log('ok', coord);
    // this.state.map?.getView().setZoom(16.932305145395418);

    if (!coord || !coord.length) return;

    await this.animatePromise(16.932305145395418, coord[0], coord[1]);

    this.functionClickViewInfomationListener({ coordinate: coord }, true);
    this.pinSearchMode(coord);
  }

  functionClickPinMarker_PinMarkerMode = (event: MapBrowserEvent) => {
    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach((layerObject: VectorLayer) => {
      if (layerObject.getClassName() === "pin-marker-vector-layer") {
        const newMarker = new Feature({
          type: "icon",
          geometry: new Point(event.coordinate),
        });
        layerObject.getSource().addFeature(newMarker);
        return;
      }
    });
  };

  handleSearchCoordinate = (value: string) => {
    if (!value) return;
    let coordinateList = value.split(",");
    let x: number = 0;
    let y: number = 0;
    if (coordinateList && coordinateList.length === 2) {
      x = parseFloat(coordinateList[0]);
      y = parseFloat(coordinateList[1]);
    }
    homePageStore.GetVn2000ToWgs84(x, y).then((res) => {
      let isInside = false;
      this.props.listPolygon &&
        this.props.listPolygon.map((itemPolygon: any) => {
          if (mapHelper.isPointInPolygon(res[1], res[0], itemPolygon)) {
            isInside = true;
            return;
          }
        });
      if (isInside) {
        // let center = [res[1],res[0]];
        console.log(res)
        this.pinSearchMode([res[0], res[1]]);
        this.functionClickViewInfomationListener({ coordinate: [res[0], res[1]] });
        history.push(
          `${UrlCollect.PlanningMapViewDetail}2?lng=${x}&lat=${y}&searchType=codinate`
        );
      } else {
        ShowNotification(
          viVN.Errors.CoordinateOutSidePolygon,
          NotificationMessageType.Warning,
          NotificationPosition.Center
        );
      }
    })
    // proj4.defs(
    //   "EPSG:3405",
    //   "+proj=tmerc +lat_0=0 +lon_0=107.75 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,-0.00928836,0.01975479,-0.00427372,0.252906278 +units=m +no_defs"
    // );
    // register(proj4);
    // var center = transform([x, y], "EPSG:3405", "EPSG:4326");
    // console.log(center)

  };
  
  pinSearchMode = (coordinate: any, isViewCenter: any = false) => {
    if(!isViewCenter) this.state.map?.getView().setCenter(coordinate);
    const LayersCurrents = this.state.map?.getLayers();
    const anchorXUnits: any = "fraction";
    const anchorYUnits: any = "pixels";
    const PinMarkerLayer = new VectorLayer({
      zIndex: 99999999,
      className: "pin-marker-vector-layer",
      source: new VectorSource({
        features: [],
      }),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 22],
          anchorXUnits: anchorXUnits,
          anchorYUnits: anchorYUnits,
          src: require("../../assets/icon/red-marker.png"),
        }),
      }),
    });
    if (!this.checkPinMarker()) {
      LayersCurrents?.push(PinMarkerLayer);
    }
    LayersCurrents?.forEach((layerObject: VectorLayer) => {
      if (layerObject.getClassName() === "pin-marker-vector-layer") {
        layerObject.getSource().clear();
        const newMarker = new Feature({
          type: "icon",
          geometry: new Point(coordinate),
        });
        layerObject.getSource().addFeature(newMarker);
        return;
      }
    });
  };

  checkPinMarker = () => {
    let currentLayers = this.state.map?.getLayers();
    currentLayers?.forEach((LayerObject: VectorLayer) => {
      if (LayerObject.getClassName() === "pin-marker-vector-layer") {
        return true;
      }
    });
    return false;
  };

  setHeightViewport = () => {
    const HeaderContainer = (document.getElementById("header-map-page-container") as HTMLElement);
    const HeaderContainerHeight = HeaderContainer ? HeaderContainer.offsetHeight : 0;
    console.log("test height HeaderContainerHeight", HeaderContainerHeight);
    console.log("test height window.innerHeight", window.innerHeight);
    console.log("test height window.innerHeight - HeaderContainerHeight", window.innerHeight - HeaderContainerHeight);

    this.setState(
      {
        mapContainerStyle: {
          height: `${window.innerHeight - HeaderContainerHeight}px`,
        },
      },
      () => {
        this.state.map?.updateSize();
      }
    );
  };

  functionHandleClickLayerToDirectDetail = (pixel: any, coordinate: any) => {
    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().planningId
    ) {
      window.open(
        UrlCollect.PlanningMapViewDetail + feature.getProperties().planningId
      );
    }
  };

  functionHoverHightlightAndShowTooltip = (pixel: any) => {
    const tooltipContainer = document.getElementById(
      "tooltip-container-openlayer"
    ) as HTMLElement;
    let left = pixel[0] + 20;
    let top = pixel[1] + 20;
    if (tooltipContainer) {
      tooltipContainer.style.left = left + "px";
      tooltipContainer.style.top = top + "px";
      tooltipContainer.style.opacity = "1";
    }

    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().tooltipValue
    ) {
      tooltipContainer.innerHTML = feature.getProperties().tooltipValue;
    } else {
      tooltipContainer.style.opacity = "0";
    }
  };

  functionHoverHightlightAndShowPopUp = (pixel: any) => {
    const popupContainer = document.getElementById(
      "popup-container-openlayer"
    ) as HTMLElement;
    if (popupContainer) {
      popupContainer.style.right = 50 + "px";
      popupContainer.style.bottom = 50 + "px";
      popupContainer.style.opacity = "1";
      popupContainer.style.display = "block";
    }

    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().tooltipValue &&
      feature.getProperties().tooltipValue &&
      feature.getProperties().acreage &&
      feature.getProperties().population
    ) {
      popupContainer.innerHTML = `<div>
            <span id="title"> ${feature.getProperties().tooltipValue || "--"
        }</span><br/>
            <span id="title">Diện tích:</span> ${feature.getProperties().acreage || "--"
        }<br/>
            <span id="title">Dân số:</span>${feature.getProperties().population || "--"
        } <br />
            <span id="title">Quyết định phê duyệt:</span> ${feature.getProperties().report || "--"
        } <br />
            </div>
            `;
    } else {
      popupContainer.style.opacity = "0";
      popupContainer.style.display = "none";
    }
  };

  functionClickViewInfomationListener = (evt: any, calledFromSearchCoordinate?: boolean) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    const listTitleLayerVisible: TileLayer[] = [];
    const listVectorImageLayerVisible: VectorImageLayer[] = [];
    const view = mapObject.getView();
    const viewResolution = view.getResolution();
    const buttonTabMapinfomation = document.getElementById(
      "ButtonControlMapInfomationTab"
    );
    const mapInfomationContainer = document.getElementById(
      "mapInfomationContainer"
    );
    const planningInfomationSmallPopupElement = (document.getElementById("planning-infomation-small-popup") as HTMLElement);
    let layerIndex = 0;
    console.log('CLick to map and view info');
    const getFeaturesWithTitleLayer = (titleLayer: TileLayer) => {
      console.log('titleLayer_xxx', titleLayer);
      const source: any = titleLayer.getSource();
      const layerId = source.getParams()['LayerId'];
      const currentLayerSettingModel = this.state.listLayerSettingModel.filter(x => x.id === layerId)[0]
      const url = source.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        view.getProjection(),
        { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
      );
      console.log(evt.coordinate);

      const className: string = titleLayer.getClassName();

      if (url) {
        Jquery.ajax({
          type: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: (featureCollection: ResponeFromServeClickShowInfomationFeature) => {
            //console.log("featureCollection", featureCollection)
            if (featureCollection.features.length > 0) {
              const _geojsonObject = featureCollection;
              const ListInfoMation: SingleMapInfoMationModel[] = [];

              const featureInfo: any = featureCollection.features[0];
              _geojsonObject.features = [featureInfo];


              if (featureInfo.id == this.state.prevFeatureId && !calledFromSearchCoordinate) {

                //tắt cha
                this.props.SaveInfomationList([]);
                this.props.SaveInfomationRelatedList([]);
                if (this.props.isProject && this.props.setDataProject) this.props.setDataProject(".")
                this.setState({ prevFeatureId: "" });
                this.removeHiglightVectorLayer();
                return
              } else {
                this.getPlanningLayerByCoord(evt.coordinate[0], evt.coordinate[1]);
              }
              this.setState({ prevFeatureId: featureInfo.id });

              /*
              * Lay thong tin de hien len bang thuoc tinh ben tay trai
              * */

              const setDataForTabInformation = (featuresProperies: any) => {
                console.log('check featuresProperies', featuresProperies);
                if (this.props.isProject && this.props.setDataProject) {
                  this.props.setDataProject(featureCollection.features[0].id)
                }
                if (currentLayerSettingModel) {
                  const layerSettingModel = currentLayerSettingModel;
                  console.log('layerSettingModel', layerSettingModel);
                  const colsRaw = ConvertColsDataDetailViewToStandardData(
                    layerSettingModel.display_name.cols
                  );
                  console.log('colsRaw', colsRaw);
                  const displayDetails = layerSettingModel.display_name.viewdetail;
                  console.log('displayDetails', displayDetails);
                  if (displayDetails.use) {
                    displayDetails.cols.map((columnName) => {
                      ListInfoMation.push({
                        label: colsRaw[columnName],
                        value: featuresProperies[columnName],
                      });
                    });
                  }
                } else if (this.state.relatedLayer) {
                  let relatedLayer: any = this.state.relatedLayer;
                  const colsRaw: any = {};
                  const displayDetails: any = []
                  //console.log('relatedLayer_YYYYYYYYYYYYYYYYYYyyy',relatedLayer);
                  if (Array.isArray(relatedLayer.display_name.cols) && relatedLayer.display_name.cols.length > 0) {
                    relatedLayer.display_name.cols.map((col: any) => {
                      if (col.col != '__gid'
                        && col.col != '____gid'
                        && col.col != 'id'
                        && col.col != 'ten_loai_dat') {
                        displayDetails.push(col.col);
                        colsRaw[col.col] = col.alias;
                      }
                    })
                  }
                  if (Array.isArray(displayDetails) && displayDetails.length > 0) {
                    displayDetails.map((columnName) => {
                      ListInfoMation.push({
                        label: colsRaw[columnName],
                        value: featuresProperies[columnName],
                      });
                    });
                  }
                  //console.log('colsRaw_XXXXXXXXXXXXXXXXXXXXXXXXXXx',colsRaw);
                }
                else if (className.includes('_sdd')) {
                  if (className.includes("_qhc")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHC_SDD.cols
                    );
                    if (fixedDisplayNameQHC_SDD.viewdetail.use) {
                      fixedDisplayNameQHC_SDD.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  } else if (className.includes("_qhpk")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHPK_SDD.cols
                    );
                    if (fixedDisplayNameQHPK_SDD.viewdetail.use) {
                      fixedDisplayNameQHPK_SDD.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  } else if (className.includes("_qhct")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHCT_SDD.cols
                    );
                    if (fixedDisplayNameQHCT_SDD.viewdetail.use) {
                      fixedDisplayNameQHCT_SDD.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  }
                }
                else if (className.includes('_giaothong')) {
                  if (className.includes("_qhc")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHC_GIAOTHONG.cols
                    );
                    if (fixedDisplayNameQHC_GIAOTHONG.viewdetail.use) {
                      fixedDisplayNameQHC_GIAOTHONG.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  } else if (className.includes("_qhpk")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHPK_GIAOTHONG.cols
                    );
                    if (fixedDisplayNameQHPK_GIAOTHONG.viewdetail.use) {
                      fixedDisplayNameQHPK_GIAOTHONG.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  } else if (className.includes("_qhct")) {
                    const colsRaw = ConvertColsDataDetailViewToStandardData(
                      fixedDisplayNameQHCT_GIAOTHONG.cols
                    );
                    if (fixedDisplayNameQHCT_GIAOTHONG.viewdetail.use) {
                      fixedDisplayNameQHCT_GIAOTHONG.viewdetail.cols.map((columnName) => {
                        ListInfoMation.push({
                          label: colsRaw[columnName],
                          value: featuresProperies[columnName],
                        });
                      });
                    }
                  }
                }
              }
              // n.features.map(features => MergeData(features.properties))
              console.log('featureCollection__xxx', featureCollection);
              setDataForTabInformation(featureCollection.features[0].properties)

              /********************************************************************/
              this.props.SaveInfomationList(ListInfoMation);
              console.log('check ListInfoMation', ListInfoMation);

              if (!this.state.isLoadedAllVectorSource) {
                let _isHaveHighlightVectorlayer = false;
                listLayer.forEach((_layer) => {
                  if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer")) {
                    _isHaveHighlightVectorlayer = true;
                    const _newVectorSource = new VectorSource({
                      features: new GeoJSON().readFeatures(_geojsonObject),

                    })

                    _layer.setSource(_newVectorSource)


                  }
                })
                console.log('_isHaveHighlightVectorlayer', _isHaveHighlightVectorlayer);
                if (!_isHaveHighlightVectorlayer) {
                  console.log('_geojsonObject', _geojsonObject)
                  listLayer.push(
                    new VectorLayer({
                      className: "highlight-vectorlayer",
                      source: new VectorSource({
                        features: new GeoJSON().readFeatures(_geojsonObject),
                      }),
                      zIndex: 99999,
                      style: new Style({
                        stroke: new Stroke({
                          color: 'rgba(0,230,241,1)',
                          width: 2,
                        }),
                        fill: new Fill({
                          color: 'rgba(223,16, 188,0.5)',
                        }),
                      }),
                    })
                  )
                }
              }
            } else {
              this.removeHiglightVectorLayer();

              if (this.props.isProject && this.props.setDataProject) this.props.setDataProject(".")

              layerIndex++;
              if (layerIndex < listTitleLayerVisible.length) {
                getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
              } else {
                this.props.SaveInfomationList([]);
                this.props.SaveInfomationRelatedList([])
              }
            }
          },
        });
      }
    }
    planningInfomationSmallPopupElement?.click();
    if (buttonTabMapinfomation && mapInfomationContainer) {
      if (buttonTabMapinfomation.classList.contains("collapsed"))
        buttonTabMapinfomation.click();
      if (!mapInfomationContainer.getAttribute("open"))
        mapInfomationContainer.setAttribute("open", "");
    }
    listLayer.forEach((layer) => {
      if ((layer instanceof TileLayer) && (layer.getClassName() != 'ol-layer') && (!layer.getClassName().includes("related-layer")) && layer.getVisible()) listTitleLayerVisible.push(layer)
      if ((layer instanceof VectorImageLayer) && layer.getVisible()) listVectorImageLayerVisible.push(layer);
    })
    listTitleLayerVisible.reverse();
    listVectorImageLayerVisible.reverse();
    listTitleLayerVisible.sort((a, b) => b.getZIndex() - a.getZIndex());
    if (listTitleLayerVisible.length > 0) {
      getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
    } else {
      this.props.SaveInfomationList([]);
      this.props.SaveInfomationRelatedList([])
    }
  };

  functionHightLightPolygon = (listPolygon: any) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    let _isHaveHighlightVectorlayer = false;
    const feature: any[] = [];

    listPolygon.map((boundariesData: any) => {
      const format = new WKT();

      const featureObject = format.readFeature(boundariesData, {
        dataProjection: this.props.projection,
        featureProjection: this.props.projection,
      });
      feature.push(featureObject);
    });
    listLayer.forEach((_layer) => {
      if ((_layer instanceof VectorImageLayer) && (_layer.getClassName() === "filter-vectorlayer")) {
        _isHaveHighlightVectorlayer = true;
        const _newVectorSource = new VectorSource({
          features: feature,
        })
        _layer.setSource(_newVectorSource)
      }
    })
    if (!_isHaveHighlightVectorlayer) {
      listLayer.push(
        new VectorImageLayer({
          className: "filter-vectorlayer",
          source: new VectorSource({
            features: feature,
          }),
          zIndex: 9000,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(0,230,241,1)',
              width: 3,
            }),
            fill: new Fill({
              color: 'rgba(223,16, 188,0.5)',
            }),
          }),
        })
      )
    }

  }

  functionHandleDisplayGeneralPlanningMode = (OpenlayerMapObject: Map) => {
    const self = this;
    OpenlayerMapObject.on("pointermove", function (e) {
      if (selected !== null) {
        selected.setStyle(undefined);
        selected = null;
      }

      OpenlayerMapObject.forEachFeatureAtPixel(e.pixel, function (f: any) {
        const highlightStyle = new Style({
          fill: new Fill({
            color: "rgba(251,130,50,0.5)",
            //color: "rgba(251,28,28,1)",
          }),
          stroke: new Stroke({
            color: "rgba(251,28,28,1)",
            width: 1,
          }),
        });
        selected = f;
        f.setStyle(highlightStyle);
        return true;
      });
      if (e.dragging) {
        (document.getElementById(
          "tooltip-container-openlayer"
        ) as HTMLElement).style.opacity = "0";
      } else {
        self.functionHoverHightlightAndShowTooltip(
          self.state.map.getEventPixel(e.originalEvent)
        );
        self.functionHoverHightlightAndShowPopUp(
          self.state.map.getEventPixel(e.originalEvent)
        );
      }
    });

    OpenlayerMapObject.on("singleclick", function (e) {
      self.functionHandleClickLayerToDirectDetail(
        self.state.map.getEventPixel(e.originalEvent), e.coordinate
      );
    });
  };

  functionHandleZoomToClickedCord = (OpenlayerMapObject: Map) => {
    const self = this;
    const listLayer = OpenlayerMapObject.getLayers();
    const titleLayerForPick: TileLayer[] = [];
    listLayer.forEach((layer) => {
      if ((layer instanceof TileLayer) && (layer.getClassName() === `title-layer-Polygon-${planningIdInfo.HOME_MAP_LAYER_ID}` || layer.getClassName() === `title-layer-${planningIdInfo.HOME_MAP_LAYER_ID}`)) titleLayerForPick.push(layer);
    })

    OpenlayerMapObject.on("singleclick", function (e) {
      if (self.state.currentZoom < 12) {
        const viewResolution = OpenlayerMapObject.getView().getResolution();
        const viewProjection = OpenlayerMapObject.getView().getProjection();
        const source: any = titleLayerForPick[0]?.getSource();
        const url = source.getFeatureInfoUrl(
          e.coordinate,
          viewResolution,
          viewProjection,
          { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
        );

        if (url) {
          Jquery.ajax({
            type: "POST",
            url: url,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: (featureCollection: ResponeFromServeClickShowInfomationFeature) => {
              if (featureCollection.features.length > 0) {
                console.log('trong');
                self.setState({
                  currentZoom: 13.1,
                  currentCoordinate: [e.coordinate[0], e.coordinate[1]],
                })
              } else {
                console.log('ngoài');
              }
            },
          });
        }
      }
    });
  };

  async checkSearchSearchUrl() {
    if (this.props.coordinate && this.props.coordinate.search) {
      let lng = new URLSearchParams(this.props.coordinate.search).get("lat");
      let lat = new URLSearchParams(this.props.coordinate.search).get("lng");
      let searchType = new URLSearchParams(this.props.coordinate.search).get(
        "searchType"
      );
      console.log(searchType);
      if (searchType === "no-search") {
        return;
      }

      if (searchType === "place") {
        const mapObject: Map = this.state.map;

        await this.animatePromise(18, lat, lng);

        this.pinSearchMode([lat, lng]);
        this.functionClickViewInfomationListener({ coordinate: [lat, lng] });

      } else {
        let value = lat + "," + lng;
        console.log(value)
        this.handleSearchCoordinate(value);
      }
    }
  }

  setHeaderForTable() {
    this.props.listLayer?.map((layer) => {
      let tableArray = layer.table ? layer.table.split("_") : [];
      if (tableArray.some(item => item.includes("sdd"))) {
        this.props.SaveHeaderForTable(
          layer.display_name ? layer.display_name.cols : []
        );
      }
    });
  }

  openPrintWindow = () => {
    let dataHtml = '';
    this.props.listMapInfomations && this.props.listMapInfomations.length > 0 && this.props.listMapInfomations.map((item: any) => {
      dataHtml += `<div>${item.label}: ${item.value ? item.value : ""}</div>`;
    })
    const self = this;
    let myWindow = window.open("", "PRINT", "height=500,width=1024");
    let title = "Bản đồ " + document.title;
    myWindow?.document.write("<html><head><title>" + title + "</title>");
    myWindow?.document.write("</head><body >");
    myWindow?.document.write(
      `<div
      className="modal-body test"
      style="padding: 0px; height: 90%;"
    >
      <div class="mapname" id="mapname" style="text-align: center; padding: 10px; font-size: 20px; font-weight: bold">${self.props.planningModel && self.props.planningModel.name}</div>
      <div
        style="width: 100%; height: 100%;"
        className="page"
        id="page"
      >
        <div style="width: 25%; float: left; padding: 0px 15px; position: absolute; z-index: 99; left: 0px; top: 50px; background: #fff; height: 850px;">
        <h3 style="text-align:center">Thông tin đối tượng</h3>
        ${dataHtml}
        </div>
        <div style="width:100%; float:left">
        <img
          id="imagemap"
          style="width:100%; height:auto;" 
          src=${document.getElementById("imagemap")?.getAttribute("src")}
          alt="Image Map"
        />
        </div>
        <p style="margin-left: 28%"><span style="color: red">* Chú ý: </span><span>Kết quả tra cứu chỉ có tính chất tham khảo</span>
        </p>
      </div>
    </div>`
    );
    myWindow?.document.write("</body></html>");

    myWindow?.document.close(); // necessary for IE >= 10
    myWindow?.focus(); // necessary for IE >= 10*/
    myWindow?.print();
    return true;
  }

  viewCurrentLocaltion = () => {
    navigator.geolocation.getCurrentPosition((position: any) => {
      if (position && position.coords) {
        this.pinSearchMode([
          position.coords.longitude,
          position.coords.latitude,
        ]);
        this.functionClickViewInfomationListener({
          coordinate: [
            position.coords.longitude,
            position.coords.latitude,
          ]
        })
        console.log({
          coordinate: [
            position.coords.longitude,
            position.coords.latitude,
          ]
        })
        homePageStore.GetWgs84ToVn2000(
          position.coords.longitude,
          position.coords.latitude).then((res) => {
            history.push(
              `${UrlCollect.PlanningMapViewDetail}2?lng=${res[0]}&lat=${res[1]}&searchType=codinate`
            )
          })
      }
    });
  };

  changeInteraction() {
    if (!this.state.isLoadedAllVectorSource)
      if (select !== null) {
        this.state.map?.removeInteraction(select);
      }
    select = selectClick;
    if (select !== null) {
      this.state.map?.addInteraction(select);
    }
  }

  removeHiglightVectorLayer() {
    const _objectMap: Map = this.state.map;
    if (!_objectMap) return;
    const _layers = _objectMap.getLayers();
    let _indexDelete = -1;
    _layers.getArray().map((_layer, _index) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer")) {
        _indexDelete = _index;
        return;
      }
    })
    if (_indexDelete != -1) _layers.removeAt(_indexDelete)
  }

  removeHiglightVectorLayerByClass(className: string) {
    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach((layerModel: Layer) => {
      if (layerModel.getClassName().includes(className)) {
        layerModel.setVisible(false);
      }
    });
  }

  clearFeatures() {
    this.state.map?.getLayers().getArray().map((item: any) => {
      if (item instanceof VectorImageLayer) {
        console.log("layer", item);
      }
    })
  }

  resetDrawnFilterDataAndRemoveInteraction = () => {
    const _mapObject: Map = this.state.map;
    if (DrawnFilterData.draw) _mapObject.removeInteraction(DrawnFilterData.draw);
    DrawnFilterData.draw = null;
    DrawnFilterData.source = null;
    DrawnFilterData.filterType = '';
  }

  createFilterDrawnVectorLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layersCollect = _mapObject.getLayers();
    let _needCreateVectorLayer = true;
    let _drawnFilterLayer: any = null;
    const _newSource = new VectorSource();

    this.resetDrawnFilterDataAndRemoveInteraction();

    DrawnFilterData.source = _newSource;
    _layersCollect.forEach((_layer) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === classNameVectorLayerFilterDrawn)) {
        _needCreateVectorLayer = false;
        _drawnFilterLayer = _layer;
      }
    })
    if (_needCreateVectorLayer) {
      const _vectorLayerDrawn = new VectorLayer({
        source: _newSource,
        className: classNameVectorLayerFilterDrawn,
        zIndex: 1000,
        style: Draw_Filter_Feature_Style,
      })
      _layersCollect.push(_vectorLayerDrawn);
    } else {
      _drawnFilterLayer.setSource(_newSource)
    }
  }

  deleteFilterDrawnVectorLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layersCollect = _mapObject.getLayers();
    let _indexDelete = -1;

    this.resetDrawnFilterDataAndRemoveInteraction();

    _layersCollect.forEach((_layer, _index) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === classNameVectorLayerFilterDrawn)) {
        _indexDelete = _index;
      }
    })
    if (_indexDelete !== -1) _layersCollect.removeAt(_indexDelete);
  }

  filterDrawnVectorLayer = (drawnType: string) => {
    const _mapObject: Map = this.state.map;
    let _drawnType: any = drawnType;
    let _geometryFunction = null;
    this.createFilterDrawnVectorLayer()
    switch (drawnType) {
      case GeometryType.POINT:
      case GeometryType.LINE_STRING:
      case GeometryType.POLYGON:
      case GeometryType.CIRCLE: {
        _drawnType = drawnType
      } break;
      case "Box": {
        _drawnType = GeometryType.CIRCLE;
        _geometryFunction = createBox();
      } break;
    }
    switch (drawnType) {
      case GeometryType.POINT: DrawnFilterData.filterType = 'Point'; break
      case GeometryType.LINE_STRING: DrawnFilterData.filterType = 'LineString'; break
      case GeometryType.POLYGON: DrawnFilterData.filterType = 'Polygon'; break
      case GeometryType.CIRCLE: DrawnFilterData.filterType = 'Circle'; break
      case "Box": DrawnFilterData.filterType = 'Box'; break
    }
    if (DrawnFilterData.source) {
      let _optionDraw: any = {
        source: DrawnFilterData.source,
        type: _drawnType,
      }
      if (_geometryFunction) {
        _optionDraw.geometryFunction = _geometryFunction;
      }
      DrawnFilterData.draw = new Draw(_optionDraw)
      DrawnFilterData.draw.on("drawstart", (evt) => {
        DrawnFilterData.source?.clear()
      })
      if (drawnType === GeometryType.POINT) {
        DrawnFilterData.draw.on("drawend", (evt) => {
          const _pointGeoMetry: any = evt.feature.getGeometry();
          document.getElementById('longitudeInput')?.setAttribute('value', _pointGeoMetry.flatCoordinates[0])
          document.getElementById('latitudeInput')?.setAttribute('value', _pointGeoMetry.flatCoordinates[1])
        })
      }
      _mapObject.addInteraction(DrawnFilterData.draw)
    }
  }

  getCurrentDrawFeature = () => {
    let _feature: Feature | null = null
    if (DrawnFilterData.source) {
      const _featureList = DrawnFilterData.source.getFeatures();
      if (_featureList.length > 0) _feature = _featureList[0];
    }
    const _format = new WKT();
    if (_feature) {
      let _featureGeometry: any = _feature.getGeometry();
      let _pointRadius: any = 0;
      let _geomText = '';
      if (DrawnFilterData.filterType === 'Circle') {
        const _mapObject: Map = this.state.map;
        const _centerCircle = _featureGeometry.getCenter();
        _geomText = `POINT(${_centerCircle[0]} ${_centerCircle[1]})`;
        _pointRadius = _mapObject.getView().getProjection().getMetersPerUnit() * _featureGeometry.getRadius();
      } else {
        if (DrawnFilterData.filterType === 'Point') {
          let _radiusInput = document.getElementById("radiusInput")?.getAttribute("value");
          if (typeof Number(_radiusInput) === 'number') {
            _pointRadius = Number(_radiusInput) || 0
          } else {
            ShowNotification(
              "Dữ liệu bạn nhập vào không đúng định dạng",
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
            _pointRadius = 0;
          }
        }
        _geomText = _format.writeGeometry(_featureGeometry);
      }
      return {
        coordinate: _geomText,
        r: _pointRadius,
        type: DrawnFilterData.filterType,
      }
    }
    return null
  }


  deleteHightlightFilterLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layers = _mapObject.getLayers();
    console.log("filter-vectorlayer", _layers);
    let _removeIndex = -1;
    _layers.forEach((_layer, _index) => {
      if ((_layer instanceof VectorImageLayer) && _layer.getClassName() === 'filter-vectorlayer') {
        _removeIndex = _index;
      }
      if (_removeIndex !== -1) {
        _layers.removeAt(_removeIndex)
      }
    })

  }

  pinMarkerProjects = (listProjects: any[]) => {

    let latitude = 0;
    let longitude = 0;

    // For each project add marker
    function parseMultiPoint(point: string) {
      // MULTIPOINT(107.985757224437 14.0285382605199)"
      const matches = point.match(/([\d.]+) ([\d.]+)/);
      if (matches && matches.length === 3) {
        // Index 1 is latitude, Index 2 is longitude
        latitude = parseFloat(matches[1]);
        longitude = parseFloat(matches[2]);
      }

      return [latitude, longitude];
    }

    try {
      // const coordinates = parseMultiPoint(stringCoordinates);

      const LayersCurrents = this.state.map?.getLayers();
      const anchorXUnits: any = "fraction";
      const anchorYUnits: any = "pixels";

      const PinMarkerLayer = new VectorLayer({
        zIndex: 9999,
        className: "projects-pin-marker-vector-layer",
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 22],
            anchorXUnits: anchorXUnits,
            anchorYUnits: anchorYUnits,
            src: require("../../assets/icon/red-marker-project.png"),
          }),
        }),
      });

      listProjects.forEach(project => {
        const point = parseMultiPoint(project.randomPoint);
        const newMarker = new Feature({
          type: "icon",
          geometry: new Point(point),
        });
        newMarker.setProperties({
          isProject: true,
          name: project.name,
          tooltipCoordiante: point,
          // desc: "ahihi",
        });
        newMarker.on("singleclick", (e) => { console.log("ahihih"); });
        PinMarkerLayer.getSource().addFeature(newMarker);
      });

      LayersCurrents?.push(PinMarkerLayer);


      this.functionHandleHover(true);
    } catch (err) {
      throw err;
    }
  };

  functionHandleHover = (turnOn: boolean) => {
    const map = this.state.map;
    const tooltipContainer = document.getElementById("tooltip-openlayer");

    if (turnOn && tooltipContainer) {
      map.on("pointermove", function (e: any) {
        var hit = map.forEachFeatureAtPixel(e.pixel, function (feature: any, layer: any) {
          if (feature.getProperties().isProject) {
            setTooltipCoordinateState(feature.getProperties().tooltipCoordiante);
            return true;
          }

          return false;
        });
        if (hit) {
          map.getTargetElement().style.cursor = 'pointer';
          functionHoverShowTooltip(map, map.getEventPixel(e.originalEvent), tooltipContainer);

        } else {
          map.getTargetElement().style.cursor = '';
          tooltipContainer.style.display = "none";
        }
      })
    }
    const setTooltipCoordinateState = (data: any) => {
      this.setState({
        projectTooltipCoordinate: data
      });
    }
    const functionHoverShowTooltip = (map: any, pixel: Pixel, tooltipContainer: HTMLElement) => {
      if (tooltipContainer) {
        tooltipContainer.style.left = `${pixel[0]}px`;
        tooltipContainer.style.top = `${pixel[1]}px`;
        tooltipContainer.style.display = 'block';
      }

      const feature = map?.forEachFeatureAtPixel(pixel, function (feature: any) {
        // console.log(feature)
        return feature;
      });
      tooltipContainer.innerHTML = `<div style="display: flex; flex-direction: column; align-items: center; gap: 10px;"> 
              <p>${feature.getProperties().name}</p>
              <div style="color: blue; text-decoration: underline; cursor: pointer;">
                Xem chi tiết
              </div>
            </div>`
    }
  }

  projectTooltipOnclick = async () => {
    const tooltipCotnainer = document.getElementById("tooltip-openlayer");
    if (!tooltipCotnainer) return;
    tooltipCotnainer.style.display = 'none';
    await this.animatePromise(18, this.state.projectTooltipCoordinate[0], this.state.projectTooltipCoordinate[1]);
    this.functionClickViewInfomationListener({ coordinate: [this.state.projectTooltipCoordinate[0], this.state.projectTooltipCoordinate[1]] });
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(history.location.search);
    const queryMapCenter = [];
    const queryLongitude = searchParams.get('lng');
    const queryLatitude = searchParams.get('lat');
    const queryZoom = searchParams.get('zoom');

    if (queryLongitude && queryLatitude) {
      queryMapCenter.push(parseFloat(queryLongitude));
      queryMapCenter.push(parseFloat(queryLatitude));
    }
    const defaultBaseMapUrl =
      this.props.defaultBaseMapUrl ||
      "https://mt0.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}";
    var defaultCenter = queryMapCenter.length > 0 ? queryMapCenter : (this.props.defaultCenter || config.defaultMapCenter);
    const defaultZoom = this.props.defaultZoom || 9;
    const defaultMaxZoom = this.props.maxZoom || 24;
    const defaultMinZoom = this.props.minZoom || 1;
    const defaultListLayer = this.props.listLayer || [];
    let ListLayerDefault = 0;
    const defaultExtend = this.props.extent || [
      config.defaultLeftCornerLng,
      config.defaultLeftCornerLat,
      config.defaultRightCornerLng,
      config.defaultRightCornerLat,
    ];
    const DefaultLayers: Array<
      TileLayer | ImageLayer | VectorLayer | VectorImageLayer
    > = [
        new TileLayer({
          source: new XYZ({
            url: defaultBaseMapUrl,
            maxZoom: this.props.maxZoom,
            crossOrigin: "anonymous",
          }),
        }),
      ];
    this.setState({
      listLayerSettingModel: defaultListLayer,
      // isLoadedAllVectorSource: this.props.isGeneralPlanningWithDetail ? false : !defaultListLayer.length,
    })
    ListLayerDefault = defaultListLayer.length;
    defaultListLayer.map((layerData, index) => {
      const layerImage: any = new TileLayer({
        visible: layerData.is_check,
        // zIndex: layerData.z_index,
        zIndex: this.getZIndexBasedOnStyle(layerData.style, layerData.id) || layerData.z_index,
        minZoom: layerData.min_zoom,
        maxZoom: layerData.max_zoom,
        source: new TileWMS({
          url: layerData.wms,
          params: {
            LAYERS: `${config.DataStore}:${layerData.table}`,
            LayerId: layerData.id,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: 'anonymous'
        }),
        // className: TileLayerClassName(layerData.id),
        className: `${TileLayerClassNameBasedOnStyle(layerData.id.toString(), layerData.style)}`,
      });
      // const vectorLayer = new VectorImageLayer({
      //   source: new VectorSource(),
      //   opacity: 0.5,
      //   style: Default_Feature_Style,
      //   zIndex: layerData.z_index,
      //   visible: layerData.is_check,
      //   className: VectorImageLayerClassName(layerData.id)
      // });
      // fetch(`${config.DoMainNameGeoServer}?service=WFS&version=1.1.0&request=GetFeature&outputFormat=application/json&typename=${layerData.table}`).then(res => res.json().then((data) => {
      //   _updateLoadLayerState();
      //   vectorLayer.setSource(new VectorSource({
      //     features: new GeoJSON().readFeatures(data),
      //   }))
      // }).catch((error) => {
      //   _updateLoadLayerState();
      // })).catch((error) => {
      //   _updateLoadLayerState();
      // })
      DefaultLayers.push(layerImage);
      // DefaultLayers.push(vectorLayer);
      if (index === 0) {
        this.setState({
          currentLayer: layerImage,
          currentLayerSettingModel: layerData,
          primaryIdLayer: layerData.id
        });
      }
    });
    this.setHeightViewport();
    const IdIntervalSettingMap = setInterval(() => {
      if (this.state.mapContainerStyle) {
        var mousePositionControl = new MousePosition({
          coordinateFormat: createStringXY(4),
          projection: "EPSG:4326",
          className: "custom-mouse-position",
          target: document.getElementById("mouse-position") as HTMLElement,
          undefinedHTML: "&nbsp;",
        });
        var mapScale = new ScaleLine({
          className: "custom-map-scale",
          target: document.getElementById("map-scale") as HTMLElement,
          units: "metric",
          bar: true,
          steps: parseInt("4", 10),
          text: true,
          minWidth: 140,
        });
        var OpenlayerMapObject = new Map({
          controls: defaultControls().extend([mousePositionControl, mapScale]),
          target: "map-container-id",
          layers: DefaultLayers,
          view: new View({
            projection: this.props.projection,
            center: defaultCenter,
            zoom: defaultZoom,
            minZoom: defaultMinZoom,
            maxZoom: defaultMaxZoom,
            extent: defaultExtend,
          }),
        });

        OpenlayerMapObject.addOverlay(
          new Overlay({
            position: [0, 0],
            element: document.getElementById("null") as HTMLLinkElement,
          })
        );

        this.props.SaveMapBase(OpenlayerMapObject);

        if (this.props.isGeneralPlanning)
          this.functionHandleDisplayGeneralPlanningMode(OpenlayerMapObject);

        OpenlayerMapObject.on('moveend', () => {
          var newZoom = OpenlayerMapObject.getView().getZoom();
          console.log('zoom end, new zoom: ' + newZoom);
          this.setState({
            currentZoom: newZoom
          })
        })
        this.setState(
          {
            map: OpenlayerMapObject,
          },
          async () => {
            if (!this.props.isGeneralPlanning && !this.props.isGeneralPlanningWithDetail) {
              this.handleOnOffViewInfomation(true);
            }
            if (this.props.isGeneralPlanningWithDetail) {
              this.handleOnOffViewInfomation(true);
            }
            console.log("getLayers", this.state.map.getLayers());

            if (queryZoom) {
              await this.animatePromise(parseInt(queryZoom), defaultCenter[0], defaultCenter[1]);
            }
          }
        );
        this.props.setIsDoneConstructMap && this.props.setIsDoneConstructMap(true);
        console.log("FROM MAP - IsDoneConstructMap");

        clearInterval(IdIntervalSettingMap);
        this.checkSearchSearchUrl();
      }
    }, 500);
    //-- update handle function
    this.props.CreateOpenlayerController({
      toggleDisplayLayerRelated: this.toggleDisplayLayerRelated,
      handleOnOffViewInfomation: this.handleOnOffViewInfomation,
      toggleDisplayVectorLayer: this.toggleDisplayVectorLayer,
      handleDisplayFullScreenViewMode: this.handleDisplayFullScreenViewMode,
      handleSearchCoordinateMapDetail: this.handleSearchCoordinateMapDetail,
      handlePinMarkerMode: this.handlePinMarkerMode,
      handleMeasureMode: this.handleMeasureMode,
      handleExportMap: this.handleExportMap,
      handleZoomOut: this.handleZoomOut,
      handleZoomIn: this.handleZoomIn,
      toggleDisplayLayer: this.toggleDisplayLayer,
      resetLayerOpacity: this.resetLayerOpacity,
      addLayerManually: this.addLayerManually,
      addLayerManuallyFIXED: this.addLayerManuallyFIXED,
      changeCurrentLayer: this.changeCurrentLayer,
      changeCurrentLayerOpacity: this.changeCurrentLayerOpacity,
      changeBaseMap: this.changeBaseMap,
      handleSelectedOne: this.handleSelectOne,
      handleSelectMany: this.handleSelectMany,
      handleSearchCoordinate: this.handleSearchCoordinate,
      placeSearch: this.pinSearchMode,
      functionDisplayAllBoundariesOfAllPlanning: this.functionDisplayAllBoundariesOfAllPlanning,
      handleViewCurrentLocaltion: this.viewCurrentLocaltion,
      removeInteractionOnMap: this.removeInteractionOnMap,
      functionTurnOffFilterMode: this.functionTurnOffFilterMode,
      clearFeaturesSelected: this.clearFeaturesSelected,
      functionClickViewInfomationListener: this.functionClickViewInfomationListener,
      functionHightLightPolygon: this.functionHightLightPolygon,
      filterDrawnVectorLayer: this.filterDrawnVectorLayer,
      deleteFilterDrawnVectorLayer: this.deleteFilterDrawnVectorLayer,
      getCurrentDrawFeature: this.getCurrentDrawFeature,
      pinMarkerProjects: this.pinMarkerProjects,
      toggleDisplayPlanningRelated: this.toggleDisplayPlanningRelated,
    });

  }

  async componentDidUpdate(prevProps: any, prevState: any) {

    if (this.props.listMapInfomationsRelated !== prevProps.listMapInfomationsRelated) {
      if (this.props.listMapInfomationsRelated.length === 0) {
        const LayersCurrents = this.state.map?.getLayers();
        LayersCurrents?.forEach((layerModel: Layer) => {
          if (layerModel.getClassName().includes("related-layer")) {
            layerModel.setVisible(false);
            this.removeHiglightVectorLayerByClass("highlight-vectorlayer-related");
            this.removeHiglightVectorLayerByClass("pin-marker-vector-layer");
            this.props.SaveInfomationRelatedTable([]);
          }

        });
      } else {
        this.props.listMapInfomationsRelated.map((item: any) => {
          this.toggleDisplayPlanningRelated(item.layer, false);
        })
      }
      console.log('this.props.listMapInfomationsRelated', this.state.map?.getLayers())
    }

    // if (this.state.currentClickCoordinate !== prevState.currentClickCoordinate) {
    //   console.log('this.state.currentClickCoordinate', this.state.currentClickCoordinate)
    //   this.clickViewInfomationRelatedLayer(this.state.currentClickCoordinate);
    // }

    if (this.props.projectcoordinate && this.props.projectcoordinate !== prevProps.projectcoordinate) {
      const mapObject: Map = this.state.map;

      await this.animatePromise(18, this.props.projectcoordinate.coordinate[0], this.props.projectcoordinate.coordinate[1]);
      this.functionClickViewInfomationListener(this.props.projectcoordinate)
    }

    if (this.state.currentZoom !== prevState.currentZoom) {
      //console.log('zoom thay đổi này lắng nghe thay đổi hẳn hoi nha', prevState.currentZoom, this.state.currentZoom);
      const _mapObject: Map = this.state.map;
      if (!_mapObject) return;

      if (prevState.currentZoom < 12 && this.state.currentZoom >= 12 && this.props.isGeneralPlanningWithDetail) {
        this.removeHiglightVectorLayer();
        this.deleteHightlightFilterLayer();
        _mapObject.getLayers().forEach((item) => {
          if (item.getClassName() === `title-layer-Polygon-${planningIdInfo.HOME_MAP_LAYER_ID}` || item.getClassName() === `title-layer-${planningIdInfo.HOME_MAP_LAYER_ID}`) {
            item.setVisible(false);
          }
        })
        this.handleOnOffViewInfomation(true);
      }

      if (prevState.currentZoom > 12 && this.state.currentZoom <= 12 && this.props.isGeneralPlanningWithDetail) {
        this.removeHiglightVectorLayer();
        this.deleteHightlightFilterLayer();
        _mapObject.getLayers().forEach((item) => {
          if (item.getClassName() === `title-layer-Polygon-${planningIdInfo.HOME_MAP_LAYER_ID}` || item.getClassName() === `title-layer-${planningIdInfo.HOME_MAP_LAYER_ID}`) {
            console.log("lololo", item);
            item.setVisible(true);
          }
        })
        this.handleOnOffViewInfomation(false);
      }
    }

    if (this.state.currentZoom !== prevState.currentZoom && this.state.currentCoordinate !== prevState.currentCoordinate) {
      const _mapObject: Map = this.state.map;
      if (!_mapObject) return;
      // _mapObject.getView().setCenter(this.state.currentCoordinate);
      // _mapObject.getView().setZoom(this.state.currentZoom);

      await this.animatePromise(this.state.currentZoom, this.state.currentCoordinate[0], this.state.currentCoordinate[1])
    }


    if ((this.props.listLayer !== prevProps.listLayer)) {
      const defaultListLayerProps = this.props.listLayer || [];
      const listLayerSettingModel = this.state.listLayerSettingModel || [];
      const _mapObject: Map = this.state.map;
      if (!_mapObject) return;

      defaultListLayerProps.map((layerData, index) => {
        const layerImage: any = new TileLayer({
          visible: layerData.is_check,
          // zIndex: layerData.z_index,
          zIndex: this.getZIndexBasedOnStyle(layerData.style, layerData.id) || layerData.z_index,
          minZoom: layerData.min_zoom,
          maxZoom: layerData.max_zoom,
          source: new TileWMS({
            url: layerData.wms,
            params: {
              LAYERS: `${config.DataStore}:${layerData.table}`,
              LayerId: layerData.id,
              FORMAT: "image/png",
              VERSION: "1.1.0",
            },
            crossOrigin: 'anonymous'
          }),
          // className: TileLayerClassName(layerData.id),
          className: `${TileLayerClassNameBasedOnStyle(layerData.id?.toString(), layerData.style)}`,
        });
        _mapObject.addLayer(layerImage);
        listLayerSettingModel.push(layerData);
        if (layerData.geo_layer_name == planningIdInfo.HOME_MAP_GEO_LAYER_NAME) {
          this.functionHandleZoomToClickedCord(_mapObject);
        }
      });
      console.log('_mapObject.getLayers()', _mapObject.getLayers(), defaultListLayerProps);
      this.setState({
        listLayerSettingModel: listLayerSettingModel,
      })
    }

    if (this.state.prevFeatureId !== prevState.prevFeatureId) {
    }

    if (this.props.coordinate !== prevProps.coordinate) {
      if (!this.state.map) return;
      this.checkSearchSearchUrl();

      console.log("check this.props.coordinate", this.props.coordinate);
      console.log("check prevProps.coordinate", prevProps.coordinate);

    }
  }

  render() {
    return (
      <div
        className="openlayer-map-view-container"
        style={this.state.mapContainerStyle}
      >
        <div>
          <button
            hidden
            id="print"
            type="button"
            className="btn btn-primary position-absolute map-tools-container"
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            Launch demo modal
          </button>
          <div
            className="modal"
            id="exampleModalLong"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{ width: "1376px", height: "695px", left: "-385px" }}
              >
                <div className="modal-header">
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={this.openPrintWindow}
                      className="btn btn-success ml-3"
                    >
                      <FontAwesomeIcon
                        icon={faPrint}
                        color="white"
                      ></FontAwesomeIcon>{" "}
                      In bản đồ
                    </button>
                    <h5 className="modal-title mb-0 ml-2" id="exampleModalLongTitle">
                      Bản đồ {this.props.planningModel ? this.props.planningModel.name : document.title}
                    </h5>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ modals: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ padding: "0px", height: "90%" }}
                >
                  <div className="mapname" id="mapname" style={{ textAlign: "center", padding: "10px", fontSize: "20px", fontWeight: "bold" }}>{this.props.planningModel && this.props.planningModel.name}</div>
                  <div
                    style={{ width: "100%", height: "100%" }}
                    className="page"
                    id="page"
                  >
                    <div className="left-print" id="left-print">
                      <h3>Thông tin đối tượng</h3>
                      {this.props.listMapInfomations && this.props.listMapInfomations.length > 0 && this.props.listMapInfomations.map((item: any, index: number) =>
                        <p>{item.label}: {item.value}</p>
                      )}
                    </div>
                    <img
                      id="imagemap"
                      style={{ width: "100%", height: "100%" }}
                      alt="Image Map"
                    />
                    <p style={{ marginLeft: "25%", position: "relative", top: "-25px", backgroundColor: "white" }}><span style={{ color: "red" }}>* Chú ý: </span><span>Kết quả tra cứu chỉ có tính chất tham khảo</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MapContainer />
        <div id="mouse-position"></div>
        <div id="map-scale"></div>
        <div id="tooltip-container-openlayer"></div>
        <div id="popup-container-openlayer"></div>
        <div id="tooltip-openlayer" onClick={this.projectTooltipOnclick}></div>

        <AssistiveChipsView
          layerGroupsPlace={this.props.layerGroupsPlace}
          layerGroups_KHQHSDD={this.props.layerGroups_KHQHSDD}
          layerGroups_GT={this.props.layerGroups_GT}
          layerGroups_DADTXD={this.props.layerGroups_DADTXD}
          fixedDataGroupLayers={this.props.fixedDataGroupLayers}
          setFixedDataGroupLayers={(data: any) => this.props.setFixedDataGroupLayers(data)}
          idPlanningToHide={this.props.idPlanningToHide}
          isProject={this.props.isProject}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  dataFilter: state.mapData.dataFilter,
  featuresSelected: state.openlayer.featuresSelected,
  listMapInfomations: state.mapData.infomations,
  listMapInfomationsRelated: state.mapData.infomationsRelated,
  listMapInfomationsRelatedTable: state.mapData.infomationsRelatedTable,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      CreateOpenlayerController: OpenlayerStore.CreateOpenlayerController,
      SaveOpenlayerFeaturesSelected: OpenlayerStore.SaveOpenlayerFeaturesSelected,
      SaveInfomationList: MapDataStore.SaveInfomationList,
      SaveInfomationRelatedList: MapDataStore.SaveInfomationRelatedList,
      SaveInfomationRelatedTable: MapDataStore.SaveInfomationRelatedTable,
      SaveInfomationForTable: MapDataStore.SaveInformationForTable,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
      SaveMapBase: MapDataStore.SaveMapBase,
      SetDisplayInfomationPopup: MapToolsPanelsStore.SetDisplayInfomationPopup,
      ShowLoading: LoadingAction.ShowLoading,
      HiddenLoading: LoadingAction.HiddenLoading,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(OpenlayersMapView));
